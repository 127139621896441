import { p4pApiAtom } from 'data'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function useIsUserAdmin() {
  const api = useAtomValue(p4pApiAtom)

  const navigate = useNavigate()

  const [isUserAdmin, setIsUserAdmin] = useState(false)

  useEffect(() => {
    // Checks if the currently signed in user have the correct privileges
    // to access this page.
    async function checkIfUserIsAdmin() {
      const response = await api.isUserAdmin()

      if (response && response.status === 200) {
        const isAdmin = response.data.is_admin

        // If the user is an admin, redirect them to the home page.
        if (!isAdmin) {
          navigate('/')
          return
        }

        setIsUserAdmin(isAdmin)
      }
    }

    checkIfUserIsAdmin()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isUserAdmin
}
