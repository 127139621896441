import { CustomButton } from 'components'
import { p4pApiAtom } from 'data'
import { useFormik } from 'formik'
import { useAtomValue } from 'jotai'
import { Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getIntroCyclePostcardsAtom } from '../core/atoms'
import { useMemo, useState } from 'react'
import { currentUserAtom } from 'pages/auth'
import { useIsUserAdmin } from 'utils/hooks/use-is-user-admin'

export function AdminIntroCyclePage() {
  useIsUserAdmin()

  const api = useAtomValue(p4pApiAtom)

  const currentUser = useAtomValue(currentUserAtom)

  const { postcardCollection } = useAtomValue(getIntroCyclePostcardsAtom)

  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const introCyclePostcards = useMemo(
    () =>
      postcardCollection?.items.sort(
        (a, b) => (a?.order ?? 0) - (b?.order ?? 0)
      ),
    [postcardCollection]
  )

  const initialValues = {
    email: currentUser?.email ?? '',
    intro_cycle_id: ''
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSendingEmail(true)

      try {
        const response = await api.sendIntroCycle(values)

        if (response?.status !== 200) {
          toast.error('Error while processing request')
          return
        }

        toast.success('Intro cycle email sent successfully!')
      } catch (e) {
        toast.error('Error while processing request')
        console.error(e)
      }

      setIsSendingEmail(false)
    },
    validate: (values) => {
      const error = {}

      if (values.email.length === 0) {
        error['email'] = 'Please provide an email'
      }

      if (values.intro_cycle_id.length === 0) {
        error['intro_cycle_id'] = 'Please choose which intro cycle to send'
      }

      return error
    }
  })

  const selectedIntroCyclePostcard = useMemo(
    () =>
      introCyclePostcards?.find(
        (p) => p?.sys?.id === formik.values.intro_cycle_id
      ),
    [introCyclePostcards, formik.values.intro_cycle_id]
  )

  return (
    <div className="AdminIntroCyclePage">
      <div className="container">
        <p className="title">Intro Cycle Email</p>
        <div className="email-container">
          <div className="email-item">
            <p>Intro Cycle:</p>

            <Dropdown>
              <Dropdown.Toggle className="intro-cycle-dropdown">
                <span>
                  {selectedIntroCyclePostcard
                    ? `Cycle ${selectedIntroCyclePostcard?.order} - ${selectedIntroCyclePostcard?.title}`
                    : 'Choose an intro cycle'}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {introCyclePostcards?.map((postcard) => (
                  <Dropdown.Item
                    key={postcard?.sys?.id}
                    onClick={() => {
                      formik.setFieldValue('intro_cycle_id', postcard?.sys?.id)
                    }}
                  >
                    Cycle {postcard?.order ?? ''} - {postcard?.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="email-item">
            <p>Email:</p>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>

          <div className="send-container">
            <CustomButton
              className="send-button"
              enabled={formik.isValid && !isSendingEmail}
              onClick={formik.handleSubmit}
            >
              {isSendingEmail ? 'Sending...' : 'Send Intro Cycle Email'}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}
