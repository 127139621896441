import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { HomePage } from 'pages/home'
import { AppWrapper, ErrorBoundary } from 'components'
import { ToastContainer } from 'react-toastify'
import { Suspense } from 'react'
import { KidQuizPage, ParentQuizPage } from 'pages/quiz'
import { ResourceRoomPage } from 'pages/resource_room'
import {
  PostcardDetailsPage,
  PostcardsByCategoryPage,
  PostcardsByGradePage,
  PostcardsPage
} from 'pages/postcards'
import {
  ToolkitDetailsPage,
  ToolkitPage,
  ToolsByCategoryPage
} from 'pages/toolkit'
import { AboutPage } from 'pages/about'
import { SubscriptionPage } from 'pages/subscription'
import { SubscriptionThankYouPage } from 'pages/subscription/pages/subscription_thank_you_page'
import { ScrollToTop } from 'components/scroll_to_top'
import { LegalDocumentPage } from 'pages/legal_document'
import { PasswordResetPage } from 'pages/auth'
import { AdminIntroCyclePage, AdminPage, AdminPostcardsPage } from 'pages/admin'

import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export function App() {
  return (
    <Suspense>
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToTop />
          <Routes>
            <Route element={<AppWrapper />}>
              {/* Main entry point for the application */}
              <Route path="/" element={<HomePage />} />

              <Route
                path="/password_reset/:token"
                element={<PasswordResetPage />}
              />

              <Route path="/toolkit" element={<ToolkitPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route
                path="/privacy-policy"
                element={
                  <LegalDocumentPage
                    id={process.env.REACT_APP_CONTENTFUL_PRIVACY_POLICY!}
                  />
                }
              />
              <Route
                path="/terms-of-use"
                element={
                  <LegalDocumentPage
                    id={process.env.REACT_APP_CONTENTFUL_TERMS_OF_USE!}
                  />
                }
              />
              <Route path="/postcards" element={<PostcardsPage />} />
              <Route path="/details/:id" element={<PostcardDetailsPage />} />
              <Route
                path="/toolkitDetails/:id"
                element={<ToolkitDetailsPage />}
              />
              <Route
                path="/catList/:id"
                element={<PostcardsByCategoryPage />}
              />
              <Route
                path="/catlist2/tool/:id"
                element={<ToolsByCategoryPage />}
              />
              <Route path="/gradelist/:id" element={<PostcardsByGradePage />} />

              {/* Resource room */}
              <Route
                path="/welcomeKit"
                element={<ResourceRoomPage tab="welcome_kit" />}
              />
              <Route
                path="/child-style"
                element={<ResourceRoomPage tab="child_style" />}
              />
              <Route
                path="/postcards-office-hours"
                element={<ResourceRoomPage tab="your_postcards" />}
              />
              <Route
                path="/userDashboard"
                element={<ResourceRoomPage tab="account_info" />}
              />

              {/* Quizes */}
              <Route path="/quiz" element={<ParentQuizPage />} />
              <Route path="/kid-quiz" element={<KidQuizPage />} />

              <Route path="/thank-you" element={<SubscriptionThankYouPage />} />
            </Route>

            <Route path="/register" element={<SubscriptionPage />} />

            {/* Admin */}
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin/postcards" element={<AdminPostcardsPage />} />
            <Route
              path="/admin/intro-cycle"
              element={<AdminIntroCyclePage />}
            />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </Suspense>
  )
}
