import { useAtomValue } from 'jotai'
import { currentUserAtom } from 'pages/auth'
import { Link } from 'react-router-dom'
import { useIsUserAdmin } from 'utils/hooks/use-is-user-admin'

/**
 * Page that contains all of the administrative options for this
 * project.
 *
 * It is important to note that if there is no currently signed in
 * user or they do not have administrative privileges, they are
 * redirected to the home page.
 */
export function AdminPage() {
  const isUserAdmin = useIsUserAdmin()

  const currentUser = useAtomValue(currentUserAtom)

  if (!isUserAdmin || !currentUser) {
    return <></>
  }

  return (
    <div className="AdminPage">
      <h3>Admin Menu</h3>
      <p className="welcome">Hi, {currentUser.name}!</p>
      <p className="description">Choose below what you would like to manage.</p>

      <Link to="/admin/postcards">
        <div className="AdminOption">Postcards</div>
      </Link>

      <Link to="/admin/intro-cycle">
        <div className="AdminOption">Intro Cycle</div>
      </Link>
    </div>
  )
}
