import { Suspense } from 'react'
import { AdminPageContent } from '../components/admin_page_content'
import { LoadingView } from 'components'
import { useIsUserAdmin } from 'utils/hooks/use-is-user-admin'

export function AdminPostcardsPage() {
  const isUserAdmin = useIsUserAdmin()

  return (
    <div className="AdminPage">
      {isUserAdmin && (
        <Suspense fallback={<LoadingView />}>
          <AdminPageContent />
        </Suspense>
      )}
    </div>
  )
}
