import { contentfulApiAtom } from 'data'
import { atom } from 'jotai'
import { atomsWithQuery } from 'jotai-tanstack-query'

type EmailWrapperPaginationData = {
  limit: number
  skip: number
}

export const emailWrapperPaginationDataAtom = atom<
  EmailWrapperPaginationData | undefined
>(undefined)

function getAllEmailWrappersKey(paginationData?: EmailWrapperPaginationData) {
  return [
    'getAllEmailWrappersAtom',
    paginationData?.limit,
    paginationData?.skip
  ]
}

export const [getAllEmailWrappersAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)
  const paginationData = get(emailWrapperPaginationDataAtom)

  return {
    queryKey: getAllEmailWrappersKey(paginationData),
    queryFn: async () => {
      if (!paginationData || !paginationData.limit) return null

      const response = await api.getAllEmailWrappers({
        limit: paginationData.limit,
        skip: paginationData.skip
      })

      return response
    }
  }
})

function getIntroCyclePostcardsKey(ids: string[]) {
  return ['getIntroCyclePostcardsAtom', ids]
}

export const [getIntroCyclePostcardsAtom] = atomsWithQuery((get) => {
  const api = get(contentfulApiAtom)

  const ids = [
    process.env.REACT_APP_CONTENTFUL_INTRO_CYCLE_1!,
    process.env.REACT_APP_CONTENTFUL_INTRO_CYCLE_2!,
    process.env.REACT_APP_CONTENTFUL_INTRO_CYCLE_3!,
    process.env.REACT_APP_CONTENTFUL_INTRO_CYCLE_4!,
    process.env.REACT_APP_CONTENTFUL_INTRO_CYCLE_5!
  ]

  return {
    queryKey: getIntroCyclePostcardsKey(ids),
    queryFn: async () => {
      const response = await api.getIntroCyclePostcards({
        ids
      })

      return response
    }
  }
})
